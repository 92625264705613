import React, { memo } from 'react';

type Option = {
  value: string,
  label: string,
  description: string
};

type Props = {
  value: string | null;
  onChange: (...args: any[]) => any;
  options: Option[]
};

const QuizChoiceInput = memo(({ value, onChange, options }: Props) => {
  return (
    <div className="QuizChoiceInput">
      {
        options.map((option: Option) => {
          const classNames = ['QuizChoiceInput__Item'];
          if (value === option.value) {
            classNames.push('QuizChoiceInput__Item--active');
          }

          return (
            <div
              role="button"
              key={option.value}
              className={classNames.join(' ')}
              onClick={() => onChange(option.value)}
            >
              <div className="QuizChoiceInput__Item__Name">
                <div>{ option.label }</div>
              </div>
              { option.description }
            </div>
          );
        })
      }
    </div>
  );
});

export default QuizChoiceInput;
