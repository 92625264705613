import * as R from 'ramda';
import memoize from '../../../common/utils/memoize';
import * as surveyReducer from '../reducers';

const getIds = (state) => state.survey.ids;
const getSurvey = (state, id) => state.survey.items[id];
const getReport = (state, id) => state.survey.reports[id];
const getTemplates = (state) => state.survey.templates;

export const all = memoize.createSelector(
  [getIds],
  (state, ids) => R.values(ids).map((id) => surveyReducer.findById(state, id)),
);

export const item = memoize.createSelector(
  [getSurvey],
  (state, survey) => {
    if (!survey) return undefined;

    return surveyReducer.findById(state, survey.id);
  },
);

export const report = memoize.createSelector(
  [getReport],
  (state, r) => r,
);


export const templates = memoize.createSelector(
  [getTemplates],
  (state, items) => items,
);
